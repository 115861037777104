<template>
    <div class="module-cont-box">
        <div class="module-title">
            <div class="main-title">CASE INTRODUCTION</div>
            <span>案例介绍</span>
        </div>
        <div class="module-main-cont">
            <a-row>
                <a-col v-for="(item,index) in caseList" :key="index" :lg="8" :md="12" :sm="12">
                    <a :href="item.url" class="case-item">
                        <span style="position:relative;display: inline-block;">
                            <div class="bg"></div>                            
                            <img :src="item.img" style="width:300px;height:300px;" />
                        </span>
                        <div class="case-txt">
                            <h3>{{item.title }}</h3>
                            <p>{{ item.desc }}</p>
                        </div>
                    </a>
                </a-col>
            </a-row>
        </div>
    </div>
</template>
<script>
    export default{
        name:'caseIntroduction2',
        data(){
            return{
                caseList:[],
                curCase:{}
            }
        },
        created(){
            this.loadCaseInfo();
        },
        methods:{
            loadCaseInfo(){
                // this.$service.get("getCaseIntroduction2")
                this.$service.Ers76.listContent({"sid":"24612476b0c011efba8546cc601cd7e2","status":1}).then(resp=>{
                    console.log("getCaseIntroduction2",resp);
                    this.caseList=resp.data;
                    if(resp.data.length>0){
                        this.curCase=resp.data[0];
                    }
                })
            }
        }
    }
</script>
<style lang="less" scoped>

    .module-title{
        margin-bottom: 20px;padding-left:30px;
        .main-title{font-size: 20px; color: @primary-color; font-weight: bold;margin-bottom: 5px;}
    }
    .module-main-cont{
       .case-item{
         text-align: center; display: block;
         img{border-radius: 3px;}
         .bg{background: linear-gradient(to bottom, rgba(255,255,255,0),  @primary-color);  position: absolute;left: 0;top:0;width: 100%;height: 100%; display: none;}
         .case-txt{
            padding:10px 20px;
            p{opacity: 0.6;}
         }
       }
       .case-item:hover{
            .bg{display: inline-block;}
       }
    }
    @media (min-width: 991px){
        
  }
</style>